import React from 'react';
import videoFile from './nib2.mp4';
import poster from './images/video-poster.jpg';
import logo from './images/logo3.svg';
import './App.scss';

import Videojs from './video.js';

const videoJsOptions = {
  autoplay: false,
  playbackRates: [0.5, 1, 1.25, 1.5, 2],
  controls: true,
  preload:false,
  poster: poster,
  sources: [
    {
      src: videoFile,
      type: 'video/mp4',
    },
  ],
};

function App() {

  return (
    <div className="App clearfix">
      <header className="App-header">
         <div className=""><img alt="Nib logo" src={logo} id="logo" width="70%" /></div>
      </header>
      <section id="App-body">
        <Videojs {...videoJsOptions} />
      </section>
    </div>
  );
}

export default App;
